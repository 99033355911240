@use "@angular/material" as mat;

@font-face {
  font-family: "Roboto";
  src: url(https://storage.googleapis.com/novit/fuentes/Roboto-Regular.ttf);
}

@include mat.core();
@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($typography);

.mat-datepicker-dialog {
  max-width: 90vw !important;
  mat-dialog-container {
    padding: 0;
  }
  ngx-mat-calendar {
    width: 750px !important;
    max-width: 90vw !important;
  }
  .mat-datepicker-content-touch {
    max-height: unset !important;
  }
}

.mat-card.carta {
  margin-bottom: 0.5em;
}

.divider-primary {
  border-top-color: $primary;
}

// FIXES DEL DATERANGE
* {
  .mat-date-range-input-container {
    align-items: flex-start !important;
  }

  .mat-date-range-input-start-wrapper {
    overflow: visible !important;
  }

  .mat-date-range-input-separator {
    align-self: center !important;
  }
}

.ngx-mat-timepicker table {
  margin: 1em;
  padding: 10px;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
  border-top: 3px solid $primary;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background-color: $background;
}

gallery.custom-gallery {
  height: 350px;
  background-color: #f5f5f5;
}

// Oculta la duracion del audio
ngx-audio-player mat-card div div:nth-child(3) {
  display: none;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

.link {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  cursor: pointer;
}

.circulo {
  height: 22px;
  width: 22px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-primary {
  background: $primary;
  color: $primaryContrast;
  padding: 1em;
}

.label {
  padding: 8px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-warn {
  background-color: $warn;
  color: $warnContrast;
}

.bg-primary {
  background-color: $primary;
  color: $primaryContrast;
}

.bg-accent {
  background-color: $accent;
  color: $accentContrast;
}

// Graficos
.highcharts-credits {
  display: none;
}

:focus {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.v-align {
  display: flex;
  align-items: center;
}

.font08 {
  font-size: 0.8em;
}

.nowrap {
  white-space: nowrap;
}

mat-form-field {
  width: 100%;
}

mat-progress-bar {
  position: absolute !important;
  top: 0px;
}

// Para evitar el fondo de autocompletado
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #e8f0fe !important;
}

.modal-header {
  background: $primary;
  color: $primaryContrast;
  margin-top: -24px !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
  padding: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.loadSpinnerButton {
  justify-content: center;
  height: 36px !important;
  align-items: center;
}

.botonSpinner {
  height: 36px !important;
  width: 100px !important;
  // margin-bottom: 1em !important;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

.ngx-mat-timepicker table {
  margin: 1em;
  padding: 10px;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 5px;
  border-top: 3px solid $primary;
}

.icon-text {
  display: flex;
  align-items: center;
}

// Margenes

* {
  .m-auto {
    margin: auto !important;
  }
  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: 0.25em !important;
  }

  .m-2 {
    margin: 0.5em !important;
  }

  .m-3 {
    margin: 1em !important;
  }

  .m-4 {
    margin: 1.5em !important;
  }

  .mx-1 {
    margin-left: 0.25em !important;
    margin-right: 0.25em !important;
  }

  .mx-2 {
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }

  .mx-3 {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .mx-4 {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
  }

  .my-1 {
    margin-top: 0.25em !important;
    margin-bottom: 0.25em !important;
  }

  .my-2 {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
  }

  .my-3 {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
  }

  .my-4 {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 0.25em !important;
  }

  .mt-2 {
    margin-top: 0.5em !important;
  }

  .mt-3 {
    margin-top: 1em !important;
  }
  .mt-4 {
    margin-top: 2em !important;
  }

  .mb-1 {
    margin-bottom: 0.25em !important;
  }

  .mb-2 {
    margin-bottom: 0.5em !important;
  }

  .mb-3 {
    margin-bottom: 1em !important;
  }

  .mb-4 {
    margin-bottom: 2em !important;
  }

  .mb-5 {
    margin-bottom: 3em !important;
  }

  .mb-6 {
    margin-bottom: 4em !important;
  }

  .mb-7 {
    margin-bottom: 6em !important;
  }

  .ml-1 {
    margin-left: 0.25em !important;
  }

  .ml-2 {
    margin-left: 0.5em !important;
  }

  .ml-3 {
    margin-left: 1em !important;
  }

  .mr-1 {
    margin-right: 0.25em !important;
  }

  .mr-2 {
    margin-right: 0.5em !important;
  }

  .mr-3 {
    margin-right: 1em !important;
  }
}

// Padding

* {
  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 0.25em !important;
  }

  .p-2 {
    padding: 0.5em !important;
  }

  .p-3 {
    padding: 1em !important;
  }

  .p-4 {
    padding: 1.5em !important;
  }

  .px-1 {
    padding-left: 0.25em !important;
    padding-right: 0.25em !important;
  }
  .px-2 {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
  .px-3 {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .pl-1 {
    padding-left: 0.25em !important;
  }

  .pl-2 {
    padding-left: 0.5em !important;
  }

  .py-1 {
    padding-top: 0.25em !important;
    padding-bottom: 0.25em !important;
  }
  .py-2 {
    padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
  }
  .py-3 {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
}

// Tablas

* {
  .selected {
    // border: 3px solid $primary;
    background-color: #0000000f !important;
  }
  // .selected::after {
  //   width: 36px;
  //   height: 36px;
  //   transform: rotate(-225deg) translate(-15px, -10px);
  //   z-index: -1;
  //   background: $primary;
  // }
  .titulo-tabla {
    margin: 15px !important;
    font-weight: 500;
    color: gray;
    font-size: larger;
  }
  .titulo {
    margin: 15px !important;
    border-left: $primary 15px solid;
    padding: 0.75em;
  }

  .table-container {
    margin: 15px;
    width: calc(100% - 2em);
    background: white;

    .btn-container {
      width: calc(100% - 23px);
      height: 1px;
      text-align: end;
      z-index: 100;
    }
    .btn-container * {
      top: -23px;
      margin-left: 0.25em;
      margin-right: 0.25em;
      z-index: 100;
    }
    .filtro {
      margin-top: 1em;
      margin-left: 1em;
      width: calc(100% - 2em);
    }
    .action-bnt-cnt button {
      margin: 0.25em;
      box-shadow: none;
    }
    .mobile-label {
      display: none;
    }
    .mat-table {
      width: 100%;
    }
    mat-cell {
      padding-left: 0.5em;
    }
    @media (max-width: 599px) {
      .mobile-label {
        min-width: 100px;
        width: 100px;
        display: inline-block;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mat-header-row {
        display: none;
      }

      .mat-row {
        flex-direction: column;
        align-items: start;
        padding: 8px 24px;
        min-height: unset;
      }

      .mat-cell {
        min-height: unset;
        margin: 2px;
        margin-bottom: 10px;
      }

      mat-cell {
        padding-left: 0 !important;
      }
    }
  }
}

///////////////////////////////
// SCROLL BAR
///////////////////////////////

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  background-color: $background;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: $background;
}

::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary;
}

///////////////////////////////
// MENU
///////////////////////////////

.menu {
  .header-image {
    z-index: -1;
    position: absolute;
    min-width: 100%;
  }
  .side-image {
    z-index: -1;
    position: absolute;
    min-height: 100%;
  }

  ///////////////////////////////
  // SIDE NAV MENU
  ///////////////////////////////

  .menuLateral {
    width: 90%;
  }

  .spacer {
    flex: 1 1 auto;
  }

  mat-toolbar {
    // background-image: url("/assets/images/header.jpg");
    z-index: 10;
    position: fixed;
    padding: 0;
    box-shadow: 0px -2px 8px 5px #00000099;
    overflow: hidden;
  }

  mat-sidenav {
    overflow: hidden;
  }

  mat-sidenav-container {
    margin-top: 56px;
  }

  .logo-toolbar {
    height: calc(100% - 15px);
  }

  .logo {
    padding: 1em;
    display: flex;
    align-items: center;
    background-color: #1e2129;

    .logo-icon {
      width: 24px;
      height: 24px;
    }

    .logo-text {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.4px;
      line-height: normal;
      color: #bebebe;
    }
  }

  .user {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 0 11px 0;
    background-color: $primary900;

    .email {
      margin: 0.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 95%;
    }

    .rol {
      background-color: $primary;
      color: $primaryContrast;
      border-radius: 5px;
      padding: 2px 8px;
    }

    .avatar-container {
      .iniciales {
        font-size: 2.5em;
      }
      display: flex;
      align-items: center;
    }
  }

  mat-sidenav {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 200px;
    background-color: $primary !important;
    color: $primaryContrast !important;
    background-position-y: bottom;
    background-position-x: center;
    @media (max-width: 599px) {
    }
    @media (min-width: 600px) {
      z-index: inherit !important;
    }
  }

  .navbar-content {
    // background-color: #2d323e;

    p {
      padding: 0.5em 1em;
      margin-right: 0.5em;

      .material-icons {
        display: inline-flex;
        vertical-align: middle;
        margin-right: 1em;
      }
    }
  }

  .active {
    background: $accent;
    color: $accentContrast;
    border-radius: 0px 20px 20px 0px;
  }

  .menu-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .footer {
    .logo-footer {
      @media (max-width: 599px) {
        height: 25px;
        margin-right: 1rem;
      }
      @media (min-width: 600px) {
        height: 30px;
        margin-right: 2rem;
      }
    }
    @media (max-width: 599px) {
      padding: 0.5rem;
      font-size: 0.75rem;
    }
    @media (min-width: 600px) {
      padding: 2rem;
      font-size: 1rem;
    }
    background-image: url("/assets/images/footer.jpg");

    font-weight: 400;
    color: #cccccc !important;
  }

  .example-is-mobile .example-toolbar {
    position: fixed;
    /* Make sure the toolbar will stay on top of the content as it scrolls past. */
    z-index: 2;
  }

  h1.example-app-name {
    margin-left: 8px;
  }

  .example-sidenav-container {
    flex: 1;
  }

  .example-is-mobile .example-sidenav-container {
    flex: 1 0 auto;
  }

  .mat-drawer-content {
    background: #2d323edb;
    background: white;
    // background-image: url(/assets/images/fondo-menu.jpg);
    // background-position-x: center;
    // background-position-y: bottom;
  }

  mat-sidenav-container {
    @media (max-width: 599px) {
      margin-top: 56px !important;
    }
    @media (min-width: 600px) {
      margin-top: 64px !important;
    }
  }

  mat-nav-list {
    padding-top: 0 !important;
  }

  .icono-svg {
    margin-right: 1.5em;
    vertical-align: middle;
    display: inline-flex;
  }

  // mat-divider {
  //   border-color: white;
  // }
}

///////////////////////////////
// DETALLES ALERTA
///////////////////////////////

.detalles-alerta {
  .img-container {
    height: 260px;
    overflow-x: auto;
    overflow-y: hidden;
    img {
      height: 250px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .item-text {
    position: absolute;
    bottom: 5px;
    background: $accent;
    color: $accentContrast;
    padding: 3px;
    border-radius: 7px;
  }
}

///////////////////////////////
// Drag & Drop
///////////////////////////////

.list {
  // margin: 0 0 1em 0;
  width: 100%;
  max-width: 100%;
  // border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.box {
  padding: 15px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
  font-size: 14px;
}

.cursor-move {
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.box:last-child {
  border: none;
}

.list.cdk-drop-list-dragging .box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.no-info {
  text-align: center;
  color: gray;
  font-style: italic;
  font-size: small;
  font-weight: 100;
}

ngx-audio-player .mat-mdc-card {
  flex-direction: row;
}
.ngx-audio-player {
  flex-direction: row;
}

// ******************************** Mat Dialog ******************************** //
.custom-dialog {
  // border-top: 15px solid var(--accent-color);

  // .mat-mdc-dialog-surface {
  //   border-radius: 0 0 30px 30px !important;
  // }

  .mat-mdc-dialog-title {
    margin-bottom: 1.5em;
  }

  .mat-mdc-dialog-actions {
    padding: 0 0 1.5em 1.5em;
  }
}

// ******************************** Mat Dialog ******************************** //
mat-card-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding: 0 1.5em 1.5em 1.5em;
}

mat-form-field.no-hint .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.row-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.row-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.row-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.column-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.magico-85 {
  background-color: color-mix(
    in srgb,
    var(--mat-select-panel-background-color) 85%,
    rgba(255, 255, 255, 0.3)
  ) !important;
}

.magico-100 {
  background-color: var(--mat-select-panel-background-color) !important;
}
